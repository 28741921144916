export const oracleCertPrompt = `
Generate a unique and challenging multiple-choice question relevant for the Oracle Database SQL Certified Associate (1Z0-071) exam. Focus on key concepts of SQL, including data retrieval, data manipulation, data control, transaction control, and schema objects. The question should test foundational knowledge and understanding of Oracle SQL and its principles.

Please format your response exactly like this:

Question: [Your question here]

A) [Option A]
B) [Option B]
C) [Option C]
D) [Option D]

Correct Answer: [A/B/C/D]

Explanation: [A brief, concise explanation of why the correct answer is correct]

`;

console.log(oracleCertPrompt);
