import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import './global_theme.css';
import { useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import API_ENDPOINTS from './config'; // Import your API endpoints

function EssaysOverview() {
    const navigate = useNavigate();
    const [isBackLoading, setBackLoading] = useState(false);
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [editorContent, setEditorContent] = useState('<p>Start typing!</p>');
    const [inputFeedback, setInputFeedback] = useState(''); // To store feedback from the back-end

    const handleSubmitBack = async () => {
        setBackLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setBackLoading(false);
        navigate("/courses-overview");
    };

    const handleSubmitEssay = async () => {
        setSubmitLoading(true);
        try {
            // Extract plain text from the HTML content
            const parser = new DOMParser();
            const doc = parser.parseFromString(editorContent, 'text/html');
            const plainText = doc.body.textContent || "";

            // Ensure the essay is not empty
            if (!plainText.trim()) {
                setInputFeedback('Please enter some text before submitting.');
                setSubmitLoading(false);
                return;
            }

            // Build the request payload
            const payload = {
                Code: plainText, // Sending as 'Code' for compatibility with your back-end
                Prompt: "Please analyze the text for grammatical correctness. Please also assign a grade for the text based on your analysis out of 100 along with concise feedback.",
                ApiKey: process.env.REACT_APP_TEST_STR // Ensure this key is set in your environment variables
            };

            // Make the POST request to your back-end
            const response = await fetch(API_ENDPOINTS.essayCheck, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }

            const result = await response.json();
            setInputFeedback(result.Feedback); // Update the feedback state variable

        } catch (error) {
            console.error('Error submitting essay:', error);
            setInputFeedback('An error occurred while submitting your essay. Please try again.');
        } finally {
            setSubmitLoading(false);
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'align': []}],
            ['link', 'image'],
            ['clean'],
            ['blockquote', 'code-block'],
            [{ 'color': [] }, { 'background': [] }],
            ['formula'],
        ],
    };

    return (
        <div className="main-content">
            <h2 style={{color: '#008cba'}}>GPT-Graded Essay: Your Text Will Be Evaluated Upon Submission</h2>
            <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={setEditorContent}
                modules={modules}
                style={{height: '400px', width: '800px', marginBottom: '50px'}}
            />
            <button className="other-btn" onClick={handleSubmitBack} disabled={isBackLoading}>
                {isBackLoading ? <div className="spinner"></div> : 'Back'}
            </button>
            <button className="other-btn" onClick={handleSubmitEssay} disabled={isSubmitLoading}>
                {isSubmitLoading ? <div className="spinner"></div> : 'Submit'}
            </button>
            {isSubmitLoading && (
                <span style={{marginLeft: '10px'}}>Please wait, this could take a minute.</span>
            )}
            <br/>
            <br/>
            {/* Display the feedback */}
            {inputFeedback && (
                <div className="essayFeedbackContainer">
                    <ReactMarkdown>
                        {inputFeedback}
                    </ReactMarkdown>
                </div>
            )}
        </div>
    );
}

export default EssaysOverview;